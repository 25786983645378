<template>
  <div>

    <el-divider content-position="left">绑定google</el-divider>

    <img :src="imgurl" style="width:200px;height:200px;margin-left:60px;" v-if="isgoogle==2">

    <span style="display: block;width: 100%;text-align: center" v-if="isgoogle==1"><h3>已经绑定GOOGLE认证</h3></span>


    <el-form ref="emailformref" :model="googleform" label-width="60px" v-if="isgoogle==2">
      <el-form-item label="验证码">
        <el-input v-model="googleform.code" style="width:200px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t('tijiao')
        }}</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
export default {
  data() {
    return {
      imgurl:require('../../assets/img/loading.gif'),
      isgoogle:0,
      googleform: {
        code: '',
        secret: '',
      }
    }
  },
  created() {
    this.getgoogle();
    
  },
  methods: {
    async getgoogle() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/system/getgoogle')
      if (data) {
        if (data.code === 200) {
          this.imgurl = data.data.googleqrc;
          this.googleform.secret = data.data.secret;
          this.isgoogle = data.data.isgoogle;
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交
    async onSubmit() {

      if(!this.googleform.code)
      {
        this.$message.error('请输入验证码');
        return ;
      }
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })

      const { data } = await this.$http.post(
        '/admin/system/getgoogle',
        this.googleform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(data.msg)
          location.reload();
        } else {
          this.$message.error(data.msg)
        }
      }
      loading.close()
    }
  }
}
</script>
